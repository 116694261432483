import React, { useEffect, useState } from "react";

const ErrorMessage = ({ error, cnt }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if (error) {
      setIsVisible(true); // Reset visibility when a new error is received
      const timer = setTimeout(() => {
        setIsVisible(false);
      }, 2000); // 3 seconds

      // Cleanup the timer on component unmount or when error changes
      return () => clearTimeout(timer);
    }
  }, [cnt, error]);

  return (
    <div
      className={`mt-4 min-h-[1.5em] text-sm text-red-500 transition-opacity duration-500 ${
        isVisible ? "opacity-100" : "opacity-0"
      }`}
    >
      {error && <p>{error}</p>}
    </div>
  );
};

export default ErrorMessage;
