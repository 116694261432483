import { LogInIcon } from "lucide-react";
import React, { useContext, useState } from "react";

import { AuthContext } from "./AuthContext";
import ErrorMessage from "./ErrorMessage";

const PasswordPrompt = () => {
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { authenticate } = useContext(AuthContext);

  const [cnt, setCnt] = useState(0);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (authenticate(password)) {
    } else {
      setError("Mot de passe incorrect.");
      setCnt(cnt + 1);
    }
  };
  const handleInvalidPassword = (e) => {
    e.target.setCustomValidity("Mot de passe requis.");
  };
  const handleInput = (e) => {
    e.target.setCustomValidity("");
  };

  return (
    <div className="flex h-screen flex-col items-center justify-center">
      <form onSubmit={handleSubmit} className="relative">
        <input
          autoFocus
          className="rounded-lg border-2 border-blue-700 px-3 py-2 pr-10"
          type="password"
          placeholder="Mot de passe"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          onInvalid={handleInvalidPassword}
          onInput={handleInput}
        />
        <button
          type="submit"
          className="absolute right-2 top-1/2 -translate-y-1/2 transform"
        >
          <LogInIcon />
        </button>
      </form>
      <ErrorMessage error={error} cnt={cnt} />
    </div>
  );
};

export default PasswordPrompt;
