import "./App.css";

import React, { useContext } from "react";

import { AuthContext } from "./AuthContext";
import MainApp from "./MainApp";
import PasswordPrompt from "./PasswordPrompt";

const App = () => {
  const { isAuthenticated } = useContext(AuthContext);

  return isAuthenticated ? <MainApp /> : <PasswordPrompt />;
};

export default App;
