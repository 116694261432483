import React from "react";

const About = () => {
  return (
    <div className="m-auto w-full">
      <br />
      <br />
      <div className="m-4">
        <p>
          La cousinade Saxcé se profile doucement à l'horizon 2025 🤗 <br />
          <br />
          <p>
            <b>Lieu</b> : Environs de Versailles
          </p>
          <p>
            <b>Date envisagée</b> : Samedi 3 mai 2025
          </p>
          <br />
          <p className="text-justify">
            Si intéressé, merci de remplir le formulaire avant le{" "}
            <b>31 octobre 2024</b>.{" "}
          </p>
          <br />
          <p className="text-justify">
            Si vous comptez venir avec des personnes n'ayant pas d'accès
            internet, veuillez également les intégrer dans votre décompte de
            participants.
          </p>
          <br />
          Vos réponses nous permettront de choisir un lieu adéquat pour nous
          accueillir. <br />
          <br />
          Toutes suggestions seront les bienvenues !
        </p>
      </div>
    </div>
  );
};

export default About;
