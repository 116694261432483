import { Link, Route, BrowserRouter as Router, Routes } from "react-router-dom";
import React from "react";

import About from "./About";
import Participants from "./Participants";
import Poll from "./Poll";

const MainApp = () => {
  return (
    <Router className="flex min-h-screen flex-col">
      <div className="bg-regal-blue flex flex-col items-center p-2">
        <h1 className="mb-4 text-xl font-bold text-white">Cousinade Saxcé</h1>
        <nav className="w-full max-w-lg">
          <ul className="m-0 flex list-none justify-center gap-2 p-0">
            <li className="flex-1">
              <Link
                className="bg-regal-or hover:bg-regal-or-light block rounded-lg px-4 py-2 text-center font-bold text-white no-underline"
                to="/a-propos"
              >
                À propos
              </Link>
            </li>
            <li className="flex-1">
              <Link
                className="bg-regal-or hover:bg-regal-or-light block rounded-lg px-4 py-2 text-center font-bold text-white no-underline"
                to="/"
              >
                Formulaire
              </Link>
            </li>
            <li className="flex-1">
              <Link
                className="bg-regal-or hover:bg-regal-or-light block rounded-lg px-4 py-2 text-center font-bold text-white no-underline"
                to="/participants"
              >
                Participants
              </Link>
            </li>
          </ul>
        </nav>
      </div>
      <div className="flex flex-grow items-center justify-center">
        <Routes>
          <Route
            path="/"
            element={
              <div className="w-full max-w-lg">
                <Poll />
              </div>
            }
          />
          <Route
            path="/a-propos"
            element={
              <div className="w-full max-w-lg">
                <About />
              </div>
            }
          />
          <Route
            path="/participants"
            element={
              <div className="w-full max-w-lg">
                <Participants />
              </div>
            }
          />
        </Routes>
      </div>
    </Router>
  );
};

export default MainApp;
